// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layout-markdown-js": () => import("./../../src/layout/markdown.js" /* webpackChunkName: "component---src-layout-markdown-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fatwa-index-js": () => import("./../../src/pages/fatwa/index.js" /* webpackChunkName: "component---src-pages-fatwa-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */)
}

